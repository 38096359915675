















































































































































































































































































































































































import { ResponseDeliveryOrder, ResponseDeliveryOrderDetail } from "@interface/delivery-order.interface";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { DECIMAL_PLACES_QTY } from "@constant/global.constant";
import moment, { Moment } from "moment";
import Vue from "vue";
import { deliveryOrderService } from "@service/delivery-order.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { RequestSalesReturnCreate, RequestSalesReturnUpdate, ResponseDetailReturnItem, ResponseDetailSalesReturn, SalesReturnItemDetail } from "@interface/sales-return.interface";
import { salesReturnServices } from "@service/sales-return.service";
import { REFUND_OPTIONS, RETURN_FROM, SALES_RETURN_STATUS } from "@/models/enums/sales-return.enum";
import { DELIVERY_ORDER_STATUS } from "@/models/enums/delivery-order.enum";
import { Decimal } from "decimal.js-light";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";

export interface Row extends ResponseDetailReturnItem {
  no: number
  key: string;
  totalPack: number
  prevQtyPack: number
}

export const DISALLOW_EDIT: SALES_RETURN_STATUS[] = [
  SALES_RETURN_STATUS.CANCELLED,
  SALES_RETURN_STATUS.SUBMITTED,
];

export default Vue.extend({
  name: "FormCustomerReturn",
  components: {
    CSelectDeliveryOrderNumber: () => import(/*webpackPrefetch: true*/"@/components/shared/select-delivery-order-number/CSelectDeliveryOrderNumber.vue"),
    // CSelectRefundOptions: () => import(/*webpackPrefetch: true*/"@/components/shared/select-refund-options/CSelectRefundOptions.vue"),
    CSelectReturnFrom: () => import(/*webpackPrefetch: true*/"@/components/shared/select-return-from/CSelectReturnFrom.vue"),
    ModalPack: () => import(/*webpackPrefetch: true*/"./components/ModalPack.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DECIMAL_PLACES_QTY,
      vmDONumber: undefined as string | undefined,
      formModel: {
        customerName: "",
        deliveryOrderNumber: "",
        branchName: "",
        branchId: "",
        deliveryOrderDate: "",
        returnDate: null as Moment | null,
        refundOption: undefined as string | undefined,
        returnFrom: undefined as string | undefined,
        deliveryOrderStatus: undefined as DELIVERY_ORDER_STATUS | undefined,
        salesReturnStatus: "" as SALES_RETURN_STATUS,
      },
      formRules: {
        returnDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        refundOption: [{ required: false, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        returnFrom: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
      },
      selectedRowKeys: [] as string[],
      dtSource: [] as Row[],
      deliveryOrderId: "",
      loading: {
        table: false,
        cancel: false,
        submit: false,
        draft: false,
        find: false,
      },
      dtDetailDeliveryOrder: {} as ResponseDeliveryOrderDetail,
      dtDetailSalesReturn: {} as ResponseDetailSalesReturn,
      salesReturnId: "",
      deletedLines: [] as string[],
      modal: {
        pack: {
          index: -1,
          data: {} as Row,
          show: false,
        }
      }
    };
  },
  computed: {
    disallowEdit(): boolean {
      return DISALLOW_EDIT.includes(this.formModel.salesReturnStatus);
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isDraft(): boolean {
      return this.formModel.salesReturnStatus === SALES_RETURN_STATUS.DRAFT;
    },
    isSubmitted(): boolean {
      return this.formModel.salesReturnStatus === SALES_RETURN_STATUS.SUBMITTED;
    },
    allowCancel(): boolean {
      return this.formModel.salesReturnStatus === SALES_RETURN_STATUS.DRAFT;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.salesReturnId = this.$route.params.id;
      this.fetchDetailSalesReturn(this.salesReturnId);
    }
  },
  methods: {
    moment,
    formatterNumber,
    reverseFormatNumber,
    onSavePackDetail({ lines, deletedItem }: { lines: SalesReturnItemDetail[], deletedItem: string[] }): void {
      this.dtSource[this.modal.pack.index].detailResponseDTOS = lines;
      this.dtSource[this.modal.pack.index].deletedReturnItemDetailIds = deletedItem;
      this.dtSource[this.modal.pack.index].totalPack = lines.length;
      this.modal.pack.show = false;
    },
    showDetailPack(data: Row): void {
      if (data.detailResponseDTOS && data.detailResponseDTOS.length === 0) {
        this.addPackDetailLine(data, data.totalPack);
      }
      this.modal.pack.show = true;
      this.modal.pack.index = data.no - 1;
      this.modal.pack.data = { ...data };
    },
    addPackDetailLine(param: Row, iteration: number): void {
      for (let i = 0; i < iteration; i++) {
        param.detailResponseDTOS.push({
          no: param.detailResponseDTOS.length + 1,
          id: param.detailResponseDTOS[i]?.id || null,
          locationId: "",
          qtyPack: 0,

          location: "",
          newBatchId: "",
          newBatchNumber: "",
          newPackDate: "",
        });
      }
    },
    onChangeAmount(e: number, col: "qtyReturn" | "totalPack", record: Row): void {
      const { no } = record;
      this.dtSource[no - 1][col] = e;
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.customerreturn" });
    },
    getDetailSalesReturn(id: string): Promise<ResponseDetailSalesReturn> {
      return salesReturnServices.getDetailSalesReturn(id);
    },
    cancelSalesReturn(id: string): Promise<void> {
      return salesReturnServices.cancelSalesReturn(id);
    },
    submitSalesReturn(id: string): Promise<boolean> {
      return salesReturnServices.submitSalesReturn(id);
    },
    createSalesReturn(payload: RequestSalesReturnCreate): Promise<ResponseDetailSalesReturn> {
      return salesReturnServices.createSalesReturn(payload);
    },
    updateSalesReturn(id: string, payload: RequestSalesReturnUpdate): Promise<ResponseDetailSalesReturn> {
      return salesReturnServices.updateSalesReturn(id, payload);
    },
    getDetailDeliveryOrder(id: string): Promise<ResponseDeliveryOrderDetail> {
      return deliveryOrderService.getDetail(id);
    },
    onSelectChange(selectedRowKeys: string[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
    resetForm(): void {
      const form = this.$refs.customerReturnForm as any;
      form.resetFields();
      this.formModel = {
        customerName: "",
        deliveryOrderNumber: "",
        branchName: "",
        branchId: "",
        deliveryOrderDate: "",
        returnDate: null as Moment | null,
        refundOption: undefined as string | undefined,
        returnFrom: undefined as string | undefined,
        deliveryOrderStatus: undefined as DELIVERY_ORDER_STATUS | undefined,
        salesReturnStatus: "" as SALES_RETURN_STATUS,
      };
      this.dtSource = [];
    },
    onSelectDO({ value }: { value: string, meta: ResponseDeliveryOrder }): void {
      this.resetForm();
      this.deliveryOrderId = value;
    },
    async findDO(id: string): Promise<void> {
      try {
        this.loading.find = true;
        this.loading.table = true;
        const res = await this.getDetailDeliveryOrder(id);
        this.dtDetailDeliveryOrder = res;
        this.selectedRowKeys = [];
        this.deletedLines = [];
        this.fillInfoFromDO();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
        this.loading.find = false;
      }
    },
    fillInfoFromDO(): void {
      const {
        customerName,
        deliveryOrderNumber,
        branch,
        branchId,
        deliveryOrderDate,
        deliveryOrderLines,
        status,
      } = this.dtDetailDeliveryOrder;
      this.formModel = {
        customerName,
        deliveryOrderNumber,
        branchName: branch,
        branchId: branchId,
        deliveryOrderDate,
        returnDate: null as Moment | null,
        refundOption: undefined,
        returnFrom: undefined,
        deliveryOrderStatus: status,
        salesReturnStatus: "" as SALES_RETURN_STATUS,
      };
      this.dtSource = deliveryOrderLines.map((x, i) => ({
        // local model
        no: i + 1,
        key: x.id,
        totalPack: 1,
        prevQtyPack: 1,

        // include API model
        deliveryOrderLineId: x.id,
        detailResponseDTOS: [],
        id: "",
        invoiceARLineId: null,
        oldBatchId: x.batchNumberId,
        oldBatchNumber: x.batchNumber,
        oldPackDate: "",
        price: x.cogs,
        productCode: x.productCode,
        productId: x.productId,
        productName: x.productName,
        productUom: x.uomName,
        productUomId: x.uomId,
        qty: x.qtyPick,
        qtyReturn: x.qtyPick,
        returnTotal: new Decimal(x.cogs || 0).times(x.qtyPick || 0).toNumber(),
      }));
    },
    validateForm(source: "draft" | "submit"): void {
      const theForm = this.$refs.customerReturnForm as FormModel;
      theForm.validate((valid: boolean) => {
        if (valid && this.validateTable()) {
          if (source === "draft") {
            this.handleSaveDraft();
          } else {
            this.handleSubmit();
          }
        } else {
          this.showNotifValidationError();
        }
      });
    },
    validateTable(): boolean {
      if (!this.dtSource.length) return false;
      const line = this.dtSource.find(x => !x.qtyReturn || !x.totalPack);
      if (line) return false;
      return true;
    },
    async handleSubmit(): Promise<void> {
      try {
        this.loading.submit = true;
        if (this.salesReturnId) {
          // submit
          await this.submitSalesReturn(this.salesReturnId);
        } else {
          // create draft then submit
          const req = this.constructReqCreate();
          const res = await this.createSalesReturn(req);
          await this.submitSalesReturn(res.id);
          this.salesReturnId = res.id;
        }
        this.fetchDetailSalesReturn(this.salesReturnId);
        this.showSubmitSuccessMesssage();
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
      }
    },
    async handleSaveDraft(): Promise<void> {
      try {
        this.loading.draft = true;
        if (this.salesReturnId) {
          // update
          const req = this.constructReqUpdate();
          const res = await this.updateSalesReturn(this.salesReturnId, req);
          this.salesReturnId = res.id;
          this.fetchDetailSalesReturn(this.salesReturnId);
        } else {
          // create draft
          const req = this.constructReqCreate();
          const res = await this.createSalesReturn(req);
          this.salesReturnId = res.id;
          this.$router.replace({ name: "sales.transactionsales.customerreturn.edit", params: { id: res.id } });
        }
        this.showSubmitSuccessMesssage();
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.draft = false;
      }
    },
    constructReqCreate(): RequestSalesReturnCreate {
      const { branchId, customerId, id } = this.dtDetailDeliveryOrder;
      return {
        branchId,
        customerId,
        deliveryOrderId: id,
        invoiceARId: "",
        refundOptions: REFUND_OPTIONS.REFUND,
        returnDate: this.formModel.returnDate ? this.formModel.returnDate.format() : "",
        returnFrom: RETURN_FROM.DELIVERY_ORDER,
        returnItems: this.dtSource.map(x => ({
          deliveryOrderLineId: x.deliveryOrderLineId,
          deletedReturnItemDetailIds: [],
          id: x.id || null,
          invoiceARLineId: null,
          oldBatchId: x.oldBatchId,
          productId: x.productId,
          productUomId: x.productUomId,
          returnItemDetails: x.detailResponseDTOS,
        }))
      };
    },
    constructReqUpdate(): RequestSalesReturnUpdate {
      const reqCreate = this.constructReqCreate();
      const { branchId, customerId, deliveryOrderId } = this.dtDetailSalesReturn;
      reqCreate.branchId = branchId;
      reqCreate.customerId = customerId;
      reqCreate.deliveryOrderId = deliveryOrderId;
      const { dtSource } = this;
      dtSource.forEach((x, i) => {
        reqCreate.returnItems[i].id = x.id;
        reqCreate.returnItems[i].deletedReturnItemDetailIds = x.deletedReturnItemDetailIds ?? [];
      });
      return {
        ...reqCreate,
        deletedReturnItemIds: this.deletedLines,
      };
    },
    async fetchDetailSalesReturn(id: string): Promise<void> {
      try {
        this.loading.table = true;
        const res = await this.getDetailSalesReturn(id);
        this.dtDetailSalesReturn = res;
        this.fillInfoDetail();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    fillInfoDetail(): void {
      const {
        customerName, deliveryOrderNumber,
        branch, deliveryOrderDate,
        returnDate, returnItems,
        refundOptions, returnFrom,
        status, branchId
      } = this.dtDetailSalesReturn;
      this.formModel = {
        customerName,
        deliveryOrderNumber,
        branchName: branch,
        branchId,
        deliveryOrderDate,
        returnDate: this.moment(returnDate),
        refundOption: refundOptions,
        returnFrom,
        salesReturnStatus: status,
        deliveryOrderStatus: undefined,
      };
      this.dtSource = returnItems.map((x, i) => ({
        no: i + 1,
        key: x.id,
        totalPack: x.detailResponseDTOS.length,
        prevQtyPack: 1,
        ...x,
      }));
    },
    deleteRow(): void {
      const { dtSource } = this;
      let dt: Row[] = [];
      dtSource.forEach(x => {
        if (!this.selectedRowKeys.includes(x.key)) {
          dt.push(x);
        } else {
          if (x.id) {
            this.deletedLines.push(x.id);
          }
        }
      });
      dt.forEach((x, i) => {
        x.key = i.toString();
        x.no = i + 1;
      });
      this.selectedRowKeys = [];
      this.dtSource = dt;
    },
    handleCancel(): void {
      this.showConfirmation().then((confirm: boolean) => {
        if (!confirm) return;
        this.cancelSalesReturn(this.salesReturnId)
        .then(() => {
          this.showCancelSuccessMessage();
          this.$router.push({
            name: "sales.transactionsales.customerreturn.detail",
            params: { id: this.salesReturnId }
          });
        });
      });
    }
  }
});
