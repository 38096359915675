var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_form_customer_return") } },
    [
      _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
        _vm._v(_vm._s(_vm.formModel.salesReturnStatus || "-"))
      ]),
      _vm.isModeCreate
        ? [
            _c(
              "a-row",
              { attrs: { gutter: [10, 10], type: "flex" } },
              [
                _c("a-col", { attrs: { span: 24 } }, [
                  _c("p", { staticClass: "text-subtitle-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lbl_delivery_order_number")) + " "
                    )
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "a-space",
                        [
                          _c("CSelectDeliveryOrderNumber", {
                            staticStyle: { width: "250px" },
                            attrs: { "prop-search-by": "status~UNBILLED" },
                            on: { "on-select": _vm.onSelectDO },
                            model: {
                              value: _vm.vmDONumber,
                              callback: function($$v) {
                                _vm.vmDONumber = $$v
                              },
                              expression: "vmDONumber"
                            }
                          }),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "search",
                                loading: _vm.loading.find,
                                type: "primary",
                                disabled: !_vm.vmDONumber
                              },
                              on: {
                                click: function($event) {
                                  return _vm.findDO(_vm.deliveryOrderId)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c("a-divider")
          ]
        : _vm._e(),
      _c(
        "a-form-model",
        {
          ref: "customerReturnForm",
          attrs: { rules: _vm.formRules, model: _vm.formModel }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [10, 10], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_customer_name") } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.formModel.customerName || "-"))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_delivery_order_number") } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.formModel.deliveryOrderNumber || "-"))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.formModel.branchName || "-"))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_delivery_order_date") } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(_vm.formModel.deliveryOrderDate)
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_return_date"),
                        prop: "returnDate"
                      }
                    },
                    [
                      _vm.isSubmitted
                        ? _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(_vm.formModel.returnDate)
                                ) +
                                " "
                            )
                          ])
                        : _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("lbl_choose"),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            model: {
                              value: _vm.formModel.returnDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "returnDate", $$v)
                              },
                              expression: "formModel.returnDate"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_return_from"),
                        prop: "returnFrom"
                      }
                    },
                    [
                      _vm.isSubmitted
                        ? _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formModel.returnFrom || "-") +
                                " "
                            )
                          ])
                        : _c("CSelectReturnFrom", {
                            model: {
                              value: _vm.formModel.returnFrom,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "returnFrom", $$v)
                              },
                              expression: "formModel.returnFrom"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "danger",
                    icon: "delete",
                    disabled: !_vm.selectedRowKeys.length
                  },
                  on: { click: _vm.deleteRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.dtSource.length && _vm.formModel.customerName,
                  expression: "!dtSource.length && formModel.customerName"
                }
              ],
              attrs: { span: 12 }
            },
            [
              _c("a-alert", {
                attrs: {
                  type: "error",
                  message: _vm.$t(
                    "lbl_should_have_at_least_one_product_to_return"
                  ),
                  banner: ""
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    },
                    scroll: { x: "calc(1600px + 50%)", y: 520 },
                    loading: _vm.loading.table,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { width: "75px", "data-index": "no" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.productCode || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.productName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "oldBatchNumber",
                      attrs: { "data-index": "oldBatchNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.oldBatchNumber || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_old_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qty",
                      attrs: { "data-index": "qty", width: 90 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("toDecimalQty")(record.qty)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productUom",
                      attrs: { "data-index": "productUom", width: 90 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.productUom || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_uom")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "price",
                      attrs: { "data-index": "price" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(record.price)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_unit_price")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qtyReturn",
                      attrs: { "data-index": "qtyReturn", width: "120px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: record.qtyReturn,
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_QTY,
                                  readonly: _vm.disallowEdit,
                                  max: record.qty
                                },
                                on: {
                                  change: function(e) {
                                    return _vm.onChangeAmount(
                                      e,
                                      "qtyReturn",
                                      record
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_return")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "totalPack",
                      attrs: { "data-index": "totalPack" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("a-input-number", {
                                staticClass: "mr-2",
                                attrs: {
                                  value: record.totalPack,
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_QTY,
                                  readonly: _vm.disallowEdit
                                },
                                on: {
                                  change: function(e) {
                                    return _vm.onChangeAmount(
                                      e,
                                      "totalPack",
                                      record
                                    )
                                  }
                                }
                              }),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "dashed", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showDetailPack(record)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_detail")) + " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_pack")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "returnTotal",
                      attrs: { "data-index": "returnTotal" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(record.returnTotal)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_total")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.allowCancel
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loading.cancel
                          },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                      )
                    : _vm._e(),
                  _vm.isModeCreate || _vm.isDraft
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.draft,
                            disabled: _vm.isSubmitted
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("draft")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "save",
                        loading: _vm.loading.submit,
                        disabled: _vm.isSubmitted
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("submit")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalPack", {
        attrs: {
          "modal-data": _vm.modal.pack.data,
          "modal-index": _vm.modal.pack.index,
          detail: _vm.dtDetailSalesReturn
        },
        on: { "on-save": _vm.onSavePackDetail },
        model: {
          value: _vm.modal.pack.show,
          callback: function($$v) {
            _vm.$set(_vm.modal.pack, "show", $$v)
          },
          expression: "modal.pack.show"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }